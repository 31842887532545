<template>
  <div class="tips-container">
    <q-card class="tips">
      <svg v-if="level === 'alert'" t="1668532836284" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6654" width="16" height="16"><path d="M512 512m-403.2 0a403.2 403.2 0 1 0 806.4 0 403.2 403.2 0 1 0-806.4 0Z" fill="#F9D65D" p-id="6655"></path><path d="M512 608c-19.2 0-38.4-19.2-38.4-38.4V288c0-19.2 19.2-38.4 38.4-38.4s38.4 19.2 38.4 38.4V576c0 12.8-19.2 32-38.4 32z" fill="#FFFFFF" p-id="6656"></path><path d="M512 736m-44.8 0a44.8 44.8 0 1 0 89.6 0 44.8 44.8 0 1 0-89.6 0Z" fill="#FFFFFF" p-id="6657"></path></svg>
      <svg v-if="level === 'success'" t="1668576450014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7625" width="16" height="16"><path d="M511.950005 512.049995m-447.956254 0a447.956254 447.956254 0 1 0 895.912508 0 447.956254 447.956254 0 1 0-895.912508 0Z" fill="#20B759" p-id="7626"></path><path d="M458.95518 649.636559L289.271751 479.95313c-11.698858-11.698858-30.697002-11.698858-42.39586 0s-11.698858 30.697002 0 42.395859l169.683429 169.68343c11.698858 11.698858 30.697002 11.698858 42.39586 0 11.798848-11.598867 11.798848-30.597012 0-42.39586z" fill="#FFFFFF" p-id="7627"></path><path d="M777.62406 332.267552c-11.698858-11.698858-30.697002-11.698858-42.39586 0L424.158578 643.437164c-11.698858 11.698858-11.698858 30.697002 0 42.39586s30.697002 11.698858 42.39586 0l311.069622-311.069622c11.798848-11.798848 11.798848-30.796992 0-42.49585z" fill="#FFFFFF" p-id="7628"></path></svg>
      <svg v-if="level === 'error'" t="1668581696762" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8022" width="16" height="16"><path d="M512 981.333333C252.8 981.333333 42.666667 771.2 42.666667 512S252.8 42.666667 512 42.666667s469.333333 210.133333 469.333333 469.333333-210.133333 469.333333-469.333333 469.333333z m44.245333-469.333333l159.914667-159.914667a31.274667 31.274667 0 1 0-44.245333-44.245333L512 467.754667 352.085333 307.84a31.274667 31.274667 0 1 0-44.245333 44.245333L467.754667 512l-159.914667 159.914667a31.274667 31.274667 0 1 0 44.245333 44.245333L512 556.245333l159.914667 159.914667a31.274667 31.274667 0 1 0 44.245333-44.245333L556.245333 512z" fill="#F5222D" p-id="8023"></path></svg>
      <span class="content">{{ content }}</span>
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'message-tip',
  components: {},
  data() {
    return {
      content: '',
      level: 'success'
    }
  },
  mounted() {
    setTimeout(() => {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    }, 5000)
  }
}
</script>

<style lang="less" scoped>
.tips-container {
  display: flex;
  position: fixed;
  width: 400px;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-items: center;
  justify-content: center;
  animation: tips-down .5s ease-in-out forwards, tips-up .5s 4s ease-in-out forwards;
  z-index: 100;
  .tips {
    display: flex;
    align-items: center;
    background-color: rgba(50, 56, 64, .5);
    padding: .5rem;
    box-shadow: rgb(0 0 0 / 30%) 0 2px 8px;
    z-index: 100;
    font-size: .875rem;
    user-select: none;
  }
  .content {
    margin-left: .5rem;
  }
}
</style>
