import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    meta: {
      title: '青劲草'
    },
    component: () => import('@/views/front/QMain.vue'),
    children: [
      {
        path: 'archives',
        name: 'archives',
        meta: {
          title: '归档 - Qjc'
        },
        component: () => import('@/views/front/pages/article/QArchives.vue')
      },
      {
        path: 'about',
        name: 'about',
        meta: {
          title: '关于我 - Qjc'
        },
        component: () => import('@/views/front/pages/QAbout.vue')
      },
      {
        path: 'forks',
        name: 'forks',
        meta: {
          title: '收藏 - Qjc'
        },
        component: () => import('@/views/front/pages/QForks.vue')
      },
      {
        path: 'daily',
        name: 'daily',
        meta: {
          title: '日常 - Qjc'
        },
        component: () => import('@/views/front/pages/QDaily.vue')
      },
      {
        path: 'tags',
        name: 'tags',
        meta: {
          title: '标签 - Qjc'
        },
        component: () => import('@/views/front/pages/tag/QTag.vue')
      },
      {
        path: 'tags/:tag',
        name: 'tag',
        component: () => import('@/views/front/pages/QHome.vue')
      },
      {
        path: 'categories',
        name: 'categories',
        meta: {
          title: '分类 - Qjc'
        },
        component: () => import('@/views/front/pages/category/QCategory.vue')
      },
      {
        path: 'categories/:category',
        name: 'category',
        component: () => import('@/views/front/pages/QHome.vue')
      },
      {
        path: 'posts/:post',
        name: 'posts',
        component: () => import('@/views/front/pages/post/QPost.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/components/admin/AMain.vue'),
    children: [
      {
        path: 'article',
        name: 'aArticle',
        component: () => import('@/views/admin/page/AArticle.vue')
      },
      {
        path: 'edit',
        name: 'edit',
        component: () => import('@/views/admin/page/APubArticle.vue')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/front/pages/NotFound.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
