import messageTipVue from './index.vue'
// 定义插件对象
const MessageTip = {}
// vue的install方法，用于定义vue插件
export default MessageTip.install = function(Vue, options) {
  const MessageTipInstance = Vue.extend(messageTipVue)
  let currentMsg
  const initInstance = () => {
    // 实例化vue实例
    currentMsg = new MessageTipInstance()
    const msgBoxEl = currentMsg.$mount().$el
    document.body.appendChild(msgBoxEl)
  }
  // 在Vue的原型上添加实例方法，以全局调用
  Vue.prototype.$msgTip = {
    success(options) {
      // if (!currentMsg) {
      //   initInstance()
      // }
      initInstance()
      if (typeof options === 'string') {
        currentMsg.content = options
        currentMsg.level = 'success'
      } else if (typeof options === 'object') {
        Object.assign(currentMsg, options)
      }
      return currentMsg
    },
    error(options) {
      initInstance()
      if (typeof options === 'string') {
        currentMsg.content = options
        currentMsg.level = 'error'
      } else if (typeof options === 'object') {
        Object.assign(currentMsg, options)
      }
      return currentMsg
    },
    alert(options) {
      initInstance()
      if (typeof options === 'string') {
        currentMsg.content = options
        currentMsg.level = 'alert'
      } else if (typeof options === 'object') {
        Object.assign(currentMsg, options)
      }
      return currentMsg
    }
  }
}
