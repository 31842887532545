<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'QCard'
}
</script>

<style lang="less" scoped>
  .card {
    width: auto;
    height: auto;
    padding: 1rem;
    background: rgba(37, 37, 41, .5);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 8px;
  }
</style>
