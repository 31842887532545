import Vue from 'vue'
import dayjs from 'dayjs'
import Router from 'vue-router'
import QCard from '@/components/slots/QCard.vue'
import router from '@/router'
import MsgTip from '@/components/msg-tip'
import '@/assets/less/init.less'
import '@/assets/less/animation.less'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import 'font-pingfang-sc'

// 全局加载组件
Vue.component('QCard', QCard)
// 通知提示
Vue.use(MsgTip)
// 全局过滤器
Vue.filter('formatTime', (source, template) => {
  return dayjs(source).format(template)
})

// 解决路由多次点击控制台报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 提供页面内跳转
Vue.prototype.$goInnerURL = function(path) {
  this.$router.push({
    path: path
  })
}

// 路由跳转控制
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.use(Viewer, {
  defaultOptions: {
    inline: false, // 是否启用inline模式
    button: false, // 是否显示右上角关闭按钮
    navbar: false, // 是否显示缩略图底部导航栏
    title: false, // 是否显示当前图片标题，默认显示alt属性内容和尺寸
    toolbar: true, // 是否显示工具栏
    tooltip: true, // 放大或缩小图片时，是否显示缩放百分比，默认true
    fullscreen: true, // 播放时是否全屏，默认true
    loading: true, // 加载图片时是否显示loading图标，默认true
    loop: true, // 是否可以循环查看图片，默认true
    movable: true, // 是否可以拖得图片，默认true
    zoomable: true, // 是否可以缩放图片，默认true
    rotatable: true, // 是否可以旋转图片，默认true
    scalable: true, // 是否可以翻转图片，默认true
    toggleOnDblclick: true, // 放大或缩小图片时，是否可以双击还原，默认true
    transition: true, // 使用 CSS3 过度，默认true
    keyboard: false, // 是否支持键盘，默认true
    zoomRatio: 0.1, // 鼠标滚动时的缩放比例，默认0.1
    minZoomRatio: 0.01, // 最小缩放比例，默认0.01
    maxZoomRatio: 100, // 最大缩放比例，默认100
    show: function () {
      // 动态加载图片后，更新实例
      // console.log()
    }
  }
})
