import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  allArs: [],
  curArs: [],
  limitParam: {
    base: { from: 0, limit: 8 },
    tag: { from: 0, limit: 8 },
    category: { from: 0, limit: 8 },
    flush: 0,
    back: 0
  },
  baseLimitParam: { from: 0, limit: 8 },
  tagLimitParam: { from: 0, limit: 8 },
  categoryLimitParam: { from: 0, limit: 8 },
  nowPage: 0,
  nowArHtml: '',
  title: '',
  nowNav: [],
  tags: [],
  categories: []
}

export default new Vuex.Store({
  state,
  getters: {
    getAllArs(state) {
      return state.allArs
    },
    getCurArs(state) {
      return state.curArs
    },
    getNowNav(state) {
      return state.nowNav
    },
    getTags(state) {
      return state.tags
    },
    getLimitParam(state) {
      return state.limitParam
    },
    getBaseLimitParam(state) {
      return state.baseLimitParam
    },
    getTagLimitParam(state) {
      return state.tagLimitParam
    },
    getCategoryLimitParam(state) {
      return state.categoryLimitParam
    },
    getCategories(state) {
      return state.categories
    }
  },
  mutations: {
    reArs(state, payload) {
      state.allArs.push(payload.ars)
    },
    reCurArs(state, payload) {
      state.curArs = payload.ars
    },
    reNowPage(state, payload) {
      state.nowPage = payload.nowPage
    },
    reNowArHtml(state, payload) {
      state.nowArHtml = payload.nowArHtml
    },
    reTitle(state, payload) {
      state.title = payload.title
    },
    reNowNav(state, payload) {
      state.nowNav = payload.nowNav
    },
    reTags(state, payload) {
      state.tags = payload.tags
    },
    reLimitParam(state, payload) {
      state.limitParam = payload.limitParam
    },
    reBaseLimitParam(state, payload) {
      state.baseLimitParam = payload.baseLimitParam
    },
    reTagLimitParam(state, payload) {
      state.tagLimitParam = payload.tagLimitParam
    },
    reCategoryLimitParam(state, payload) {
      state.categoryLimitParam = payload.categoryLimitParam
    },
    reCategories(state, payload) {
      state.categories = payload.categories
    }
  },
  actions: {
  },
  modules: {
  }
})
